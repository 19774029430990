import { ChaveBot, Cloud, Filter, girl, PlayRed } from "../../assets";
import { useEffect, useRef, useState } from "react";
import BotKeysServices from "../../services/botKeys";
import { Text } from "../Text";
import {
  Chip,
  Container,
  ContainerLeftModal,
  ContainerRightModal,
  DivIcon,
  Img,
  ImgFilter,
  PlayButton,
  Relative,
  Span,
  Strong
} from "./styles";
import { InputText } from "primereact/inputtext";
import Dropdown from "../dropdown";
import { Button, DataTable, useToast } from "..";
import { Column } from "primereact/column";
import { css } from "@emotion/react";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import DialogPadrao from "../DialogPadrao";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggleGCredentialsDialog } from "../../redux/slices/flowInteractions";
import { setToast } from "../../redux/slices/user";
import { Link } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import InputFile from "./input-file";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const GCredentials: React.FC = () => {
  const {
    DeleteBotKey,
    GetBotKey,
    InsertBotKey,
    ListBotKeys,
    ListBotKeysWorkspaces
  } = BotKeysServices();
  const toast = useToast();

  const openGCredentialsDialog = useAppSelector(
    (state) => state.flowInteractions.dialogInsertGCredentials
  );
  const [files, setFiles] = useState<FileList | null>(null);
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const debouncedText = useDebounce(globalFilterValue, 600);
  const workspacesSelected = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );
  const updateKeys = useAppSelector((state) => state.workspaces.updateKeys);
  // const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const { user, company } = useAuth() as AuthContextType;
  const companyId = company?.id;
  const companyIdSelected = user.admin ? companyId : user.companyId;

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" }
    }
  });
  const [tablePropsData, setTablePropsData] = useState({});
  const counterToast = useAppSelector(
    (state) => state.user.toastApp?.count || 0
  );
  const onPage = (event: never) => {
    setLazyParams(event);
  };
  const fetch = async () => {
    try {
      const data = await ListBotKeysWorkspaces({
        offset: lazyParams.first,
        limit: lazyParams.rows,
        companyId: companyIdSelected,
        workspaces: workspacesSelected,
        name: globalFilterValue
      });

      const tableProps = {
        first: lazyParams.first,
        lazy: true,
        onPage,
        paginator: true,
        rows: lazyParams.rows,
        totalRecords: data.total,
        value: data.result
      };
      setTablePropsData(tableProps);
    } catch (e) {
      toast?.current?.show({
        severity: "error",
        summary: "Error Message",
        detail:
          "Não foi possível buscar os dados de Workspaces neste momento. Tente mais tarde.",
        life: 3000
      });
    }
  };
  const handleCreateCredentials = async () => {
    const fileContent: string[] = [];
    if (files !== null) {
      for (const file of files as FileList) {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = async (evt) => {
          if (evt?.target?.result) {
            fileContent.push(evt?.target?.result as string);
          }
          try {
            if (fileContent.length === files.length) {
              const duplicatedKeys = await InsertBotKey({
                companyId: companyIdSelected,
                googleCredential: fileContent
              });
              const duplicatedKeysSize = Object.keys(duplicatedKeys).length;
              if (duplicatedKeysSize > 0) {
                toast?.current?.show({
                  life: 5000,
                  severity: "error",
                  summary: "Erro",
                  detail: `${duplicatedKeysSize} chave(s) da lista já foram importadas.`
                });
              } else {
                toast?.current?.show({
                  life: 5000,
                  severity: "success",
                  summary: "Sucesso",
                  detail:
                    fileContent?.length > 1
                      ? "Novas chaves gravadas com sucesso."
                      : "Nova chave gravada com sucesso."
                });
              }
              setFiles(null);
              await fetch();
              dispatch(toggleGCredentialsDialog());
            }
          } catch (error) {
            dispatch(
              setToast({
                count: counterToast + 1,
                show: true,
                life: 3000,
                severity: "error",
                summary: "Erro.",
                detail: `Erro ao gravar nova chave. Por favor tente mais tarde.${JSON.stringify(
                  error,
                  null,
                  2
                )}`,
                sticky: false
              })
            );
          }
        };
        reader.onerror = async (evt) => {
          dispatch(
            setToast({
              count: counterToast + 1,
              show: true,
              life: 3000,
              severity: "error",
              summary: "Erro.",
              detail: `Erro ao ler arquivo. Por favor tente mais tarde.${JSON.stringify(
                evt?.target?.error,
                null,
                2
              )}`,
              sticky: false
            })
          );
        };
      }
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyParams, debouncedText, workspacesSelected, updateKeys]);

  const onfileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFiles(e.target.files as FileList);
  };

  const handleDelete = async (rowData: any) => {
    try {
      const ret = await DeleteBotKey(rowData.id);
      if (ret.success) {
        dispatch(
          setToast({
            count: counterToast + 1,
            show: true,
            life: 3000,
            severity: "success",
            summary: "Sucesso",
            detail:
              rowData?.botName === "Disponível"
                ? "Chave deletada com sucesso."
                : "Chave removida do bot com sucesso!",
            sticky: false
          })
        );
        await fetch();
      } else {
        dispatch(
          setToast({
            count: counterToast + 1,
            show: true,
            life: 3000,
            severity: "error",
            summary: "Erro",
            detail:
              "Não é possível desvincular uma credencial de um bot existente.",
            sticky: false
          })
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          count: counterToast + 1,
          show: true,
          life: 3000,
          severity: "error",
          summary: "Erro",
          detail: "Erro ao deletar chave. Por favor tente mais tarde.",
          sticky: false
        })
      );
    }
  };

  const saveFile = async (id: number) => {
    try {
      const GetKey = await GetBotKey(id);
      const blob = new Blob([JSON.stringify(GetKey, null, 2)], {
        type: "application/json"
      });
      const a = document.createElement("a");
      a.download = "my-key-file.json";
      a.href = URL.createObjectURL(blob);
      a.addEventListener("click", (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
      });
      a.click();
    } catch (error) {
      dispatch(
        setToast({
          count: counterToast + 1,
          show: true,
          life: 3000,
          severity: "error",
          summary: "Erro.",
          detail: `Erro ao salvar arquivo. Por favor tente mais tarde.${JSON.stringify(
            error,
            null,
            2
          )}`,
          sticky: false
        })
      );
    }
  };

  const inputStyles = css`
    display: flex;
    width: 342px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  `;
  return (
    <Container>
      <div className="flex align-items-center justify-content-start gap-2">
        <DivIcon>
          <Img src={ChaveBot} alt="Chave Bot Icon" />
        </DivIcon>
        <Text s="1.25rem" weight={600} lh="1.75rem" color="#0A043C">
          Gestão de Chave do Bot
        </Text>
      </div>
      <div className="flex mt-4 align-items-center justify-content-start gap-2">
        <Span>?</Span>
        <Text s="0.875rem" weight={500} lh="1.25rem" color="#6E6B7B">
          Sempre disponibilize <Strong>2 chaves por Bot</Strong> pois o Bot
          funciona em dois ambientes simultâneos ( Homologação e Produção ).
        </Text>
      </div>
      <div className="mt-2 w-full">
        <DataTable
          {...tablePropsData}
          filterDelay={600}
          emptyMessage="Nenhum resultado encontrado"
        >
          <Column
            field="projectId"
            headerStyle={{ width: "20%" }}
            header="Project ID"
          />

          <Column
            field="privateKey"
            header="Código da Chave"
            align="center"
            alignHeader="center"
            headerStyle={{ width: "20%" }}
            body={(rowData) => (
              <div className="flex justify-content-center">
                {rowData.privateKey}
              </div>
            )}
          />
          <Column
            field="botName"
            header="Bot"
            align="center"
            alignHeader="center"
            headerStyle={{ width: "15%" }}
            body={(rowData) =>
              rowData?.botName?.length ? (
                <div className="flex justify-content-center">
                  {rowData.botName}
                </div>
              ) : (
                <Text color="#25D366" weight={600} s="12px">
                  Disponível
                </Text>
              )
            }
          />
          <Column
            field="id"
            header="JSON"
            align="center"
            alignHeader="center"
            headerStyle={{ width: "15%" }}
            body={(rowData) => (
              <div className="w-full flex justify-content-center">
                <Chip onClick={() => saveFile(rowData.id)}>
                  <img src={Cloud} alt="Cloud Icon" />
                </Chip>
              </div>
            )}
          />

          <Column
            field="id"
            align="center"
            header=""
            alignHeader="center"
            headerStyle={{ width: "12%" }}
            body={(rowData) => (
              <div
                css={css`
                  align-items: center;
                  display: flex;
                  gap: 10px;
                  justify-content: center;
                `}
              >
                <Button
                  onClick={() =>
                    confirmDialog({
                      message: "Você deseja remover o registro?",
                      header: "Atenção",
                      icon: "pi pi-exclamation-triangle",
                      accept: () => handleDelete(rowData),
                      acceptLabel: "Sim",
                      rejectLabel: "Não"
                    })
                  }
                  label={
                    rowData?.botName === "Disponível"
                      ? "Deletar"
                      : "Remover Bot"
                  }
                  className="p-button-outlined"
                  css={css`
                    font-size: 12px;
                    padding: 4px 12px;
                  `}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
      <DialogPadrao
        displayDialog={openGCredentialsDialog}
        ref={ref}
        onHide={() => dispatch(toggleGCredentialsDialog())}
        contentStyle={{ height: "397px" }}
        headerStyle={{
          backgroundColor: "#ff0025"
        }}
      >
        <ContainerLeftModal>
          <div className="flex align-items-center">
            <Img width="37px" src={ChaveBot} alt="Chave Bot Icon" />
            <div className="ml-2 flex flex-column align-items-start justify-content-start">
              <Text color="#0A043C" s="0.9375rem" weight={600} lh="20px">
                Upload de Chave
              </Text>
              <Text
                color="#0A043C"
                s="0.875rem"
                weight={300}
                lh="20px"
                style={{ textAlign: "start" }}
              >
                Realize o upload do arquivo que foi gerado no seu Google Cloud.{" "}
              </Text>
            </div>
          </div>
          <Relative>
            <div className="relative w-full">
              <InputFile
                onChange={onfileChange}
                onAdicionarClick={handleCreateCredentials}
              />
            </div>
          </Relative>

          <Link to="/chatbots">
            <Text s="14px" color="#046AF3" style={{ margin: "0 auto" }}>
              Ir para Chatbots
            </Text>
          </Link>
        </ContainerLeftModal>
        <ContainerRightModal>
          <div className="flex justify-content-center">
            <img src={girl} alt="imgBot" />
          </div>
          <Text
            style={{ textAlign: "left" }}
            color="#FFF"
            weight={500}
            s="0.8125rem"
            lh="1rem"
          >
            Por que você deve criar chaves para os seus Bots?
          </Text>
          <Text
            style={{ textAlign: "left" }}
            color="#FFF"
            s="0.6875rem"
            weight={400}
            lh="14px"
          >
            Importe as credenciais do Google Dialogflow no{" "}
            {user.plataform.displayName} para otimizar a criação de robôs. Essas
            chaves são essenciais para garantir a integração eficiente e o
            funcionamento adequado das interações do robô.
          </Text>
        </ContainerRightModal>
      </DialogPadrao>
    </Container>
  );
};
