import {
  BotId,
  ButtonOpenModal,
  ButtonStyle,
  ButtonThreePoints,
  Card,
  DivImgAtualizadoPor,
  Divisor,
  DropDownArea,
  FlexSB,
  ImgAtualizadoPor,
  MainImage,
  MainImageDiv,
  SelectThreePoints,
  TextContent,
  TextSubTitle,
  Title,
  QrCodeDiv
} from "./styles";
import {
  setActive,
  setCompanyId,
  setDefaultFallBackMessage,
  setFirstBotInteractions,
  setName,
  setTarget,
  setWorkspaceId
} from "../../../../redux/slices/bot";
import { ThreePointsBlue, girl, RLAvatar, QrCode } from "../../../../assets";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  setBotIdActive,
  setModalClonarBotOpen,
  setTokenActive
} from "../../../../redux/slices/flowInteractions";
// import BotsServices from "../../../../services/bots";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import BotsServices from "../../../../services/bots";
import { useEffect, useState } from "react";
import { ModalClonarBot } from "../../../gestaoBot/components/modalClonarBot";
interface IChatbot {
  active: boolean;
  createdAt: string;
  dialogs: number;
  id: number;
  name: string;
  updatedAt: string;
  updatedBy: string;
  activeFlowId: number;
  token: string;
  target: number;
  isWhatsappQrCodeConnected: boolean;
}
export const Chatbot: React.FC<IChatbot> = ({
  active,
  createdAt,
  dialogs,
  id,
  name,
  updatedAt,
  updatedBy,
  activeFlowId,
  token,
  target,
  isWhatsappQrCodeConnected
}) => {
  const redirect = useNavigate();
  const dispatch = useAppDispatch();

  // const { GetBotResume } = BotsServices();
  const [open, setOpen] = useState<boolean>(false);
  const { botId } = useParams();
  const { GetBotResume, UpdateBot } = BotsServices();
  const { company } = useAuth() as AuthContextType;

  const [showEditScript, setShowEditScript] = useState(true);

  useEffect(() => {
    if (target === 4 || target === 6) {
      setShowEditScript(false);
    }
  }, []);

  const handleBotIdActive = (id: number) => {
    dispatch(setBotIdActive(id));
    dispatch(setTokenActive(token));
    redirect(`/chatbots/${id}`);
  };

  useEffect(() => {
    const BotId = botId ? parseInt(botId) : 0;
    const getResumeBot = async () => {
      dispatch(setBotIdActive(BotId));
    };
    if (!isNaN(BotId)) {
      getResumeBot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);
  const handleEditarScript = async (BotId: number) => {
    dispatch(setBotIdActive(BotId));
    const activeFlowId = await GetBotResume(BotId);
    dispatch(setName(activeFlowId?.name));
    dispatch(setActive(activeFlowId?.active));
    dispatch(setTarget(activeFlowId?.target));
    dispatch(setDefaultFallBackMessage(activeFlowId?.defaulFallbackMessages));
    dispatch(setCompanyId(company?.id));
    dispatch(setWorkspaceId(activeFlowId?.workspaceId));
    dispatch(setFirstBotInteractions(activeFlowId?.liveChatInteractions));

    redirect(`/drawflow/${BotId}/${activeFlowId?.activeBotFlowId}`);
  };
  const toggleOpenDiv = (BotId: number) => {
    dispatch(setBotIdActive(BotId));
    setOpen(!open);
  };
  const handleOpenModalClonar = () => {
    dispatch(setModalClonarBotOpen(true));
  };

  return (
    <div css={Card}>
      <ModalClonarBot />
      <DropDownArea>
        <QrCodeDiv>
          {isWhatsappQrCodeConnected && (
            <img src={QrCode} alt="Icon-WhatsApp" />
          )}
        </QrCodeDiv>
        <ButtonThreePoints onClick={() => toggleOpenDiv(id)}>
          <img src={ThreePointsBlue} alt="Icon-ThreePointsBlue" />
        </ButtonThreePoints>
        <SelectThreePoints style={{ display: open ? "block" : "none" }}>
          <ButtonOpenModal onClick={handleOpenModalClonar}>
            Clonar
          </ButtonOpenModal>
        </SelectThreePoints>
      </DropDownArea>
      {/* <div css={Flex}>
                <img width="22px" src={botRed} alt="Bot Red Image" />
                <img src={ThreePointsBlue} alt="Options link" />
            </div> */}
      <div css={MainImageDiv}>
        <img css={MainImage} src={girl} alt="imgBot" />
      </div>
      <div css={Title}>{name}</div>
      <div css={BotId}>#{id}</div>
      <div css={Divisor} />
      <div css={FlexSB}>
        <div>
          <div css={TextSubTitle}>Atendimentos</div>
          <div css={TextContent}>{dialogs}</div>
        </div>
        <div>
          <div css={TextSubTitle}>Criado</div>
          <div css={TextContent}>
            {new Date(createdAt).toLocaleDateString()}
          </div>
        </div>
        <div>
          <div css={TextSubTitle}>Atualizado em</div>
          <div css={TextContent}>
            {new Date(updatedAt).toLocaleDateString()}
          </div>
        </div>
      </div>
      <div css={Divisor} />
      <div css={FlexSB}>
        <div className="flex">
          <div css={DivImgAtualizadoPor}>
            <ImgAtualizadoPor css={ImgAtualizadoPor} imgURL={RLAvatar} />
          </div>
          <div>
            <div css={TextSubTitle}>Atualizado por</div>
            <div css={TextContent}>{updatedBy}</div>
          </div>
        </div>
        <div className="flex">
          <div css={TextSubTitle}>Status</div>
          <div className="ml-2" css={TextContent}>
            {active ? "Ativo" : "Inativo"}
          </div>
        </div>
      </div>
      <div css={Divisor} />
      <div css={FlexSB} style={{ marginTop: "0.4rem" }}>
        <button
          type="button"
          onClick={() => handleBotIdActive(id)}
          css={ButtonStyle}
        >
          Detalhes do Bot
        </button>
        {showEditScript && (
          <button
            type="button"
            onClick={() => handleEditarScript(id)}
            css={ButtonStyle}
          >
            Editar Script
          </button>
        )}
      </div>
    </div>
  );
};
