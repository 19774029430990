import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 600px;
  min-height: 259px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 25px;
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const DivIcon = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(4, 106, 243, 0.12);
`;
export const Span = styled.span`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #046af3;
  background-color: #d4e2f3;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 50%;
`;

export const Flex = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  justify-content: space-between;
`;

export const ContainerEmoji = styled.div`
  display: flex;
  background: red;
  width: 500px;
`;

export const ImgIcon = styled.button`
  border: none;
  background-color: transparent;
  :hover {
    cursor: pointer;
  }
`;
