import {
  A,
  Attachment,
  BotBlue,
  Link,
  MicBlue,
  Smile,
  ThreePointsBlue
} from "../../../../assets";
import {
  Container,
  DivIcon,
  Flex,
  Span,
  ImgIcon,
  ContainerEmoji
} from "./styles";
import { Text } from "../../../Text";
import { TextDefaultFallback } from "./Components/TextFallback";
import { EmojiModal } from "../../../Interactions/components/interacaoRobo/EmojiModal";
import { setEmojiModalOpen } from "../../../../redux/slices/flowInteractions";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Tooltip } from "primereact/tooltip";
import { useEffect } from "react";

export const UnderstoodMessages: React.FC = () => {
  const dispatch = useAppDispatch();
  const emojiModalOpen = useAppSelector(
    (state) => state.flowInteractions.emojiModal
  );

  return (
    <Container
      style={emojiModalOpen ? { height: "580px" } : { height: "100%" }}
    >
      <EmojiModal heightEmojiPicker="300px" />
      <div className="flex align-items-center justify-content-between w-full">
        <div className="flex align-items-center">
          <DivIcon>
            <img width="18px" src={BotBlue} alt="Img-bot" />
          </DivIcon>
          <Text className="ml-2" s="14px">
            Mensagem do bot para interações não compreendidas
          </Text>
        </div>
      </div>
      <Text className="mt-4" s="14px">
        <strong style={{ fontWeight: 700 }}>Dica:</strong> Selecione uma caixa
        de diálogo e pressione Ctrl+Enter para criar respostas aleatórias.
      </Text>
      <TextDefaultFallback />
      <div className="flex justify-content-between w-full">
        <div />
        <div>
          <Flex>
            <ImgIcon
              className="tooltip-Smile"
              onClick={() => {
                dispatch(setEmojiModalOpen(true));
              }}
            >
              <img src={Smile} alt="Icon-Smiles" />
            </ImgIcon>
            <Tooltip target=".tooltip-Smile">Enviar Emoji</Tooltip>
          </Flex>
        </div>
      </div>
    </Container>
  );
};
