import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { TreeSelectSelectionKeysType } from "primereact/treeselect";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { botRed } from "../../../assets";
import { useAuth } from "../../../auth/auth-provider";
import { Button, InputText, useToast } from "../..";
import ProfileService from "../../../services/ProfileService";
import { v } from "../../../util";
import PermissionTree from "./permission-tree";
import { ProfileContext } from "./profile-list";
import { useAppSelector } from "../../../redux/hooks";
import { setUpdateProfiles } from "../../../redux/slices/flowupdates";
import { useAppDispatch } from "../../../redux/hooks";
require("../../comum_css/rawCss.css");

type ProfileFormProps = {
  onSave: () => void;
};

export default ({ onSave }: ProfileFormProps) => {
  const { findProfileById, createProfile, updateProfile } = ProfileService();

  const [profile, setProfile] = useState<any>();

  const profileCtx = useContext(ProfileContext);
  const toast = useToast();

  const { user, company } = useAuth();

  const dispatch = useAppDispatch();
  const updateProfiles = useAppSelector(
    (state) => state.flowUpdates.updateProfiles
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<{ name: string; permissions: number[] }>({
    defaultValues: {
      name: "",
      permissions: []
    },
    values: profile ?? undefined,
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        permissions: v.array(v.number())
      })
    )
  });

  useEffect(() => {
    const run = async () => {
      if (profileCtx) {
        const profile = await findProfileById(profileCtx.id);
        setProfile({
          ...profile,
          permissions: profile.permissions.map((p) => p.id)
        });
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="col-8"
      css={css`
        background-color: white;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 50px;
        width: 100%;
        max-height: 100%;
      `}
    >
      <div>
        <div
          css={css`
            align-items: center;
            display: flex;
            gap: 10px;
          `}
        >
          <img src={botRed} className="mb-2" />
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <div
              className="font-bold"
              css={css`
                color: #0a043c;
                font-size: 1.02rem;
              `}
            >
              Perfil
            </div>
            <div>Descrição do perfil.</div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit(async (data) => {
            const permissions = data.permissions.filter((p) => p > 0);

            try {
              if (profileCtx) {
                await updateProfile(profileCtx.id, {
                  name: data.name,
                  permissions,
                  companyId: company ? company.id : user.companyId
                });
              } else {
                await createProfile({
                  name: data.name,
                  permissions,
                  companyId: company ? company.id : user.companyId
                });
              }

              reset();
              onSave();

              dispatch(setUpdateProfiles(!updateProfiles));
            } catch (e) {
              toast?.current?.show({
                life: 5000,
                severity: "error",
                summary: "Erro",
                detail: "Erro ao buscar dados. Por favor tente mais tarde!"
              });
            }
          })}
          css={css`
            margin-top: 25px;
          `}
        >
          <div
            className="flex, flex-column"
            css={css`
              flex: 1;
              gap: 25px;
            `}
          >
            <div>
              <div>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      id="name"
                      placeholder="Nome do perfil"
                    />
                  )}
                />
              </div>
              <div className="mt-1">
                {errors.name?.message ? errors.name?.message : null}
              </div>
            </div>

            <div>
              <PermissionTree control={control} />
              <div className="mt-1">
                {errors.permissions?.message
                  ? errors.permissions?.message
                  : null}
              </div>
            </div>

            <Button
              label={profileCtx ? "Atualizar" : "Criar perfil"}
              className="creatWorkspaceBtnform mt-3"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
