import React, { useState, useEffect, SyntheticEvent } from "react";
import { generatePath, useLocation, useParams } from "react-router";
import "./styles.css";
import ReactFlow, {
  Background,
  MiniMap,
  Controls,
  ReactFlowProps,
  ReactFlowInstance,
  Connection,
  Edge,
  EdgeChange
} from "react-flow-renderer";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import ColorSelectorNode from "./colorSelectorNode";
import newNodeDefault from "./NodeDefault";
import { Button } from "primereact/button";
import startNode from "./startNode";
import endNode from "./endNode";
import {
  onNodesChange,
  updatePos,
  organizeNodes,
  initFlow,
  setNodes,
  zerarFlow,
  setStartNodeActive,
  addUserInteractionByConnection,
  setLevels,
  onDeleteEdge,
  nodesUpdateonEdgeUpdate
} from "../../redux/slices/nodes";
import {
  initEdges,
  onConnect,
  onEdgesChange,
  onEdgeUpdate,
  zerarEdges
} from "../../redux/slices/edges";
import {
  changedPhoneComponent,
  setFlowName
} from "../../redux/slices/flowupdates";
import { IBotResume } from "../../types/iBotResume";
import BotsServices from "../../services/bots";
import BotPublishDialogServices from "../../services/botPublishDialog";
import { onLoad } from "../../util/onLoad";
import { iNodesSave, iNodesSaveObject } from "./NodeData";
import { v4 as uuidv4 } from "uuid";

import { useNavigate } from "react-router-dom";
import {
  setBotIdActive,
  setFlowIdActive,
  setTokenActive
} from "../../redux/slices/flowInteractions";
import { Container } from "./styles";
import { useToast } from "../toast-provider";
import TemplatesService from "../../services/TemplatesService";
import { useAuth } from "../../auth/auth-provider";

const initBgColor = "#FFF";

const connectionLineStyle = {
  stroke: "rgba(217, 217, 217, 1)",
  strokeWidth: "2px"
};
const nodeTypes = {
  selectorNode: ColorSelectorNode,
  defaultNode: newNodeDefault,
  startNode,
  endNode
};
const edgeTypes = {
  default: "default",
  step: "step",
  smoothstep: "smoothstep",
  straight: "straight"
};
interface IReactFlowRenderer {
  BotId?: number;
  FlowId?: number;
}
export const ReactFlowRenderer: React.FC<IReactFlowRenderer> = () => {
  const { user } = useAuth();

  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const { getTemplateFlow } = TemplatesService();

  const [bgColor, setBgColor] = useState(initBgColor);
  const { botId, flowId } = useParams();
  const [loading, setLoading] = useState(true);
  const fullScreen = useAppSelector(
    (state) => state.flowInteractions.fullScreenFlow
  );
  const toast = useToast();

  const navigate = useNavigate();
  // const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | undefined
  // >(undefined);
  // setting state in Redux slice
  const dispatch = useAppDispatch();
  const nodes = useAppSelector((state) => state.nodes);
  const edges = useAppSelector((state) => state.edges);
  // const nodeSelected = nodes?.filter((node) => node.selected === true)[0];
  const { GetBotResume } = BotsServices();
  const { GetBotPublishDialog } = BotPublishDialogServices();
  const [botResume, setBotResume] = useState<IBotResume>({} as IBotResume);
  // Initializing flow
  useEffect(() => {
    setLoading(true);
    const getResumeBot = async () => {
      try {
        let flowActive: iNodesSaveObject;
        if (isTemplate) {
          const result = await getTemplateFlow(Number(templateId));
          flowActive = result;
          setLoading(false);
        } else {
          const botResumeRetorno = await GetBotResume(
            parseInt(botId as string)
          );
          setBotResume(botResumeRetorno);
          if (flowId === "-1") {
            flowActive = (await GetBotPublishDialog({
              botId: parseInt(botId as string),
              flowId: botResumeRetorno?.activeBotFlowId
            })) as unknown as iNodesSaveObject;
            navigate(
              `/drawflow/${botId}/${botResumeRetorno?.activeBotFlowId}`,
              {
                replace: false
              }
            );
            // window.history.replaceState(null, "IMBot", path)
          } else {
            flowActive = (await GetBotPublishDialog({
              botId: parseInt(botId as string) as number,
              flowId: parseInt(flowId as string)
            })) as unknown as iNodesSaveObject;
          }
        }
        if (flowActive?.nodes.length > 0) {
          const NodesEdgesLoad = onLoad(flowActive?.nodes as iNodesSave[]);
          dispatch(setTokenActive(flowActive?.botToken as string));
          dispatch(zerarFlow());
          dispatch(zerarEdges());
          dispatch(setFlowName(flowActive?.name as string));
          dispatch(initEdges(NodesEdgesLoad.edges));
          dispatch(initFlow(NodesEdgesLoad.nodes));
          if (
            NodesEdgesLoad?.nodes?.filter(
              (node) => node.position.x === 0 && node.position.y === 0
            ).length
          ) {
            dispatch(organizeNodes(NodesEdgesLoad.edges));
          }
          dispatch(setStartNodeActive());
          setLoading(false);

          dispatch(setLevels(NodesEdgesLoad.edges));
        } else {
          const userInteractionsNewNode = [
            {
              id: uuidv4(),
              source: "",
              target: "",
              comparison: "Igual a",
              phrases: [],
              entityTypeId: -1,
              entityTypeName: ""
            }
          ];
          const NewNode = {
            id: uuidv4(),
            draggable: true,
            position: {
              x: -120,
              y: 0
            },
            selectable: true,
            selected: true,
            type: "startNode",
            data: {
              label: "Início",
              botInteractions: [
                {
                  id: uuidv4(),
                  text: `[{"type":"paragraph","children":[{"text":"Olá, bem-vindo ao seu primeiro desenvolvido pela ${user.plataform.displayName} 🚀 "}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"A conversa do seu chatbot sempre inicia através da interação do usuário."}]}]`,
                  entityTypeId: -1,
                  entityName: "",
                  sourceEntityNodeId: ""
                }
              ],
              userInteractions: userInteractionsNewNode,
              entityTypeId: -1,
              entityTypeName: "",
              sourceEntityNodeId: ""
            }
          };
          dispatch(zerarEdges());
          dispatch(zerarFlow());
          dispatch(initFlow([NewNode]));
          dispatch(setStartNodeActive());
          setLoading(false);
          dispatch(setLevels(edges));
        }
      } catch (error: any) {
        toast.current?.show({
          severity: "error",
          summary: "Erro ao buscar flow.",
          detail: error?.message
        });
      }
    };
    getResumeBot();

    // Next 6 lines just for debug
    // const NodesEdgesLoad = onLoad(JsonErroAPIRetorno as iNodesSave[])
    // dispatch(zerarFlow());
    // dispatch(zerarEdges());
    // dispatch(initEdges(NodesEdgesLoad.edges))
    // dispatch(initFlow(NodesEdgesLoad.nodes))
    // dispatch(organizeNodes(NodesEdgesLoad.edges))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isTemplate) {
      dispatch(setFlowIdActive(parseInt(flowId as string)));
      dispatch(setLevels(edges));
      dispatch(setBotIdActive(parseInt(botId as string)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowId, botId]);
  const onNodesDelete = () => {
    // dispatch(onEdgesChange(""));
    // useReactFlow().fitView();
    // dispatch(setLevels(edges));
  };

  const AddStartNode = () => {
    const hasStartNode = nodes.filter((_node) => _node.type === "startNode");
    if (hasStartNode?.length) {
      return;
    }
    const userInteractionsNewNode = [
      {
        id: uuidv4(),
        source: "",
        target: "",
        comparison: "Igual a",
        phrases: [],
        entityTypeId: -1,
        entityTypeName: ""
      }
    ];
    const NewNode = {
      id: uuidv4(),
      draggable: true,
      position: {
        x: -120,
        y: 0
      },
      selectable: true,
      selected: true,
      type: "startNode",
      data: {
        label: "Início",
        botInteractions: [
          {
            id: uuidv4(),
            text: `[{"type":"paragraph","children":[{"text":"Olá, bem-vindo ao seu primeiro desenvolvido pela ${user.plataform.displayName} 🚀 "}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"A conversa do seu chatbot sempre inicia através da interação do usuário."}]}]`,
            entityTypeId: -1,
            entityName: "",
            sourceEntityNodeId: ""
          }
        ],
        userInteractions: userInteractionsNewNode,
        entityTypeId: -1,
        entityTypeName: "",
        sourceEntityNodeId: "",
        level: 0
      }
    };

    dispatch(initFlow([NewNode]));
    dispatch(setStartNodeActive());
    dispatch(setLevels(edges));
    dispatch(changedPhoneComponent(true));
  };
  const handleConnection = (connection: Connection) => {
    const sourceLevel = nodes.filter((node) => node.id === connection.source)[0]
      .data.level;
    const targetLevel = nodes.filter((node) => node.id === connection.target)[0]
      .data.level;
    if (
      Number(targetLevel) <= Number(sourceLevel) &&
      Number(targetLevel) !== -1
    ) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro.",
        detail:
          "Conexão não permitida! Favor escolher um node abaixo em seu fluxo!",
        sticky: false
      });
    } else {
      dispatch(addUserInteractionByConnection(connection));
      dispatch(onConnect(connection));
      dispatch(setLevels(edges));
      // setTimeout(() => dispatch(setLevels(edges)), 100);
      // dispatch(setLevels(edges));
    }
  };
  const handleClickBackground = () => {
    // if(hasSelected > 1) {
    //   const
    // }
  };
  const hasSelected = () => {
    const countSelected = nodes.filter((node) => node.selected);
    return countSelected;
  };
  const handleEdgeUpdate = (oldEdge: Edge<any>, newConnection: Connection) => {
    const sourceLevel = nodes.filter(
      (node) => node.id === newConnection.source
    )[0].data.level;
    const targetLevel = nodes.filter(
      (node) => node.id === newConnection.target
    )[0].data.level;
    if (
      Number(targetLevel) <= Number(sourceLevel) &&
      Number(targetLevel) !== -1
    ) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro.",
        detail:
          "Conexão não permitida! Favor escolher um node abaixo em seu fluxo!",
        sticky: false
      });
    } else {
      dispatch(nodesUpdateonEdgeUpdate({ oldEdge, newConnection }));
      dispatch(onEdgeUpdate({ oldEdge, newConnection }));
    }
  };
  useEffect(() => {
    if (edges?.length) {
      dispatch(setLevels(edges));
    }
  }, [edges]);
  // dispatch(setLevels(edges));
  const handleEdgesChange = (changes: EdgeChange[]) => {
    dispatch(onEdgesChange(changes));
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Container fullScreen={fullScreen}>
          <div className="providerflow">
            <div className="reactflow-wrapper">
              <ReactFlow
                nodesDraggable={!isTemplate}
                nodesConnectable={!isTemplate}
                nodes={nodes}
                edges={edges}
                onNodesChange={(changes) => dispatch(onNodesChange(changes))}
                onEdgesChange={(changes) => handleEdgesChange(changes)}
                onEdgesDelete={(changes) => dispatch(onDeleteEdge(changes))}
                onConnect={(connection) => handleConnection(connection)}
                onEdgeUpdate={(oldEdge, newConnection) =>
                  handleEdgeUpdate(oldEdge, newConnection)
                }
                onNodesDelete={onNodesDelete}
                style={{ background: fullScreen ? "#0A043C" : "#FFF" }}
                nodeTypes={nodeTypes}
                connectionLineContainerStyle={connectionLineStyle}
                connectionLineStyle={connectionLineStyle}
                snapToGrid={true}
                defaultZoom={1}
                fitView={true}
                attributionPosition="bottom-right"
                deleteKeyCode={null}
              >
                <Background color={fullScreen ? "#0A043C" : "#FFF"} gap={16} />
                <Controls />
              </ReactFlow>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
