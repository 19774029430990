/* eslint-disable func-style */
import { IBotUpdate } from "../types/iBotResume";
import { useFetch2 } from "../util";

export interface CreateBotData {
  name: string;
  companyId: number;
  workspaceId: number;
  active: boolean;
  chatColor: string;
  avatarColor: string;
  target: number;
}

export interface GetBotData {
  offset?: number;
  limit?: number;
  companyId?: number;
  workspaces?: number[];
  name?: string;
}
export interface IUpdateWhatsapp {
  phone: string;
  token: string;
  metaUrl: string;
  businessPhoneId: string;
  businessAccountId: string;
  active: boolean;
}

export interface IUpdateAvatar {
  avatarColor: string;
  chatColor: string;
  name: string;
  interactions: [
    {
      position: number;
      interaction: string;
    }
  ];
  nameOnStart: boolean;
  phoneOnStart: boolean;
  emailOnStart: boolean;
  whatsappRedirect: boolean;
}
export interface IGetBotsByWorkspaces {
  workspaces: number[];
}
export interface IBotsClone {
  botSourceId: number;
  workspaceId: number;
  name: string;
}
export interface IGetBotsByWorkspacesPost {
  workspaces: number[];
  offset: number;
  limit: number;
  name?: string;
  companyId: number;
}
export interface IUpdateWhatsappModal {
  name: string;
  keepBotOnDialogInitiatedOnDevice: boolean;
}

function BotsServices() {
  const fetch = useFetch2();

  const createBot = async (data: CreateBotData) => {
    return fetch(`/api/bots`, { method: "POST" }, data);
  };

  const GetBots = async (data: GetBotData) => {
    const body: GetBotData = {};

    if (data.limit) {
      body.limit = data.limit;
    }

    if (data.offset !== undefined) {
      body.offset = data.offset;
    }

    if (data.companyId) {
      body.companyId = data.companyId;
    }

    if (data.workspaces?.length) {
      body.workspaces = data.workspaces;
    }

    if (data.name) {
      body.name = data.name;
    }

    return fetch(
      "/api/bots/list",
      {
        method: "POST"
      },
      { ...body }
    );
  };

  const GetBotResume = async (id: number) => {
    return fetch(`/api/bots/${id}`, { method: "GET" });
  };

  const UpdateBot = async (id: number, data: IBotUpdate) => {
    return fetch(`/api/bots/${id}`, { method: "PUT" }, data);
  };

  const UpdateAvatar = async (id: number, data: IUpdateAvatar) => {
    return fetch(`/api/bots/${id}/update-avatar`, { method: "POST" }, data);
  };

  const UpdateWhatsapp = async (id: number, data: IUpdateWhatsappModal) => {
    return fetch(
      `/api/bots/${id}/update-meta-config`,
      { method: "POST" },
      data
    );
  };

  const LogoutWhatsappInstance = async (botId: number) => {
    return fetch(`/api/bots/whatsapp/logout-instance/${botId}`, {
      method: "DELETE"
    });
  };

  const GetWhatsappInstanceInfo = async (botId: number) => {
    return fetch(`/api/bots/${botId}/whatsapp/info`, {
      method: "GET"
    });
  };

  const UpdateWhatsappInfo = async (
    botId: number,
    data: IUpdateWhatsappModal
  ) => {
    try {
      return await fetch(
        `/api/bots/${botId}/whatsapp/info`,
        { method: "PUT" },
        data
      );
    } catch (error) {
      console.log(`erro: ${JSON.stringify(error)}`);
    }
  };

  const GetWhatsappInstance = async (botId: number) => {
    return fetch(`/api/bots/whatsapp/instance/${botId}`, { method: "GET" });
  };
  const Clone = async (data: IBotsClone) => {
    return fetch(`/api/bots/clone`, { method: "POST" }, data);
  };

  const GetWhatsappImage = async (botId: number) => {
    return fetch(`/api/bots/whatsapp/qrCode/${botId}`, { method: "GET" });
  };

  // Comentando abaixo para mudar a request para POST
  // const ListBotsByWorksPaces = async (input: IGetBotsByWorkspaces) => {
  //   const params = new URLSearchParams();
  //   if (input.workspaces.length) {
  //     input.workspaces.map((el) => params.append("workspaces", String(el)));
  //   } else {
  //     params.append("workspaces", "0");
  //   }
  //   return fetch(`/api/bots/list-by-workspaces?${params}`, { method: "GET" });
  // }
  const ListBotsByWorksPaces = async (input: IGetBotsByWorkspacesPost) => {
    return fetch(`/api/bots/list`, { method: "POST" }, input);
  };

  const GetBotTransfer = async (botId: number) => {
    return fetch(`/api/bots/${botId}/bot-transfer-settings`, { method: "GET" });
  };

  const updateBotTransfer = async (
    botId: number,
    data: { workspaceId?: number; userId?: number }
  ) => {
    return fetch(
      `/api/bots/${botId}/bot-transfer-settings`,
      { method: "PUT" },
      data
    );
  };
  return {
    createBot,
    GetBots,
    GetBotResume,
    UpdateBot,
    UpdateWhatsapp,
    UpdateAvatar,
    ListBotsByWorksPaces,
    GetWhatsappInstance,
    GetWhatsappInstanceInfo,
    UpdateWhatsappInfo,
    LogoutWhatsappInstance,
    GetWhatsappImage,
    Clone,
    GetBotTransfer,
    updateBotTransfer
  };
}

export default BotsServices;
