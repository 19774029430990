import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import React, { useEffect, useState } from "react";
import { Modal, Overlay, Content } from "./styles";
import { Button } from "primereact/button";
import EmojiPicker from "emoji-picker-react";
import {
  setEmojiModalOpen,
  textEmoji
} from "../../../../../redux/slices/flowInteractions";

interface IEmoji {
  heightEmojiPicker?: string;
}

export const EmojiModal: React.FC<IEmoji> = ({ heightEmojiPicker }) => {
  const emojiModalOpen = useAppSelector(
    (state) => state.flowInteractions.emojiModal
  );
  const dispatch = useAppDispatch();

  const handleEmojiSelect = (e: any) => {
    dispatch(textEmoji(e.emoji));
    dispatch(setEmojiModalOpen(false));
  };

  return (
    <Overlay style={{ display: emojiModalOpen ? "block" : "none" }}>
      <Modal
        style={{ display: emojiModalOpen ? "block" : "none" }}
        open={emojiModalOpen}
      >
        <Content>
          <Button
            onClick={() => dispatch(setEmojiModalOpen(false))}
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
            aria-label="Cancel"
          />
          <EmojiPicker
            width="100%"
            height={heightEmojiPicker}
            onEmojiClick={handleEmojiSelect}
            previewConfig={{
              showPreview: false
            }}
          />
        </Content>
      </Modal>
    </Overlay>
  );
};
