import React from "react";
import Papa from "papaparse";
import { useDispatch } from "react-redux";
import { zerarFlow, initFlow } from "../../../../redux/slices/nodes";
import { zerarEdges, initEdges } from "../../../../redux/slices/edges";
import { setShowModalCSV } from "../../../../redux/slices/flowInteractions";
import { v4 as uuidv4 } from "uuid";
import { Button } from "primereact/button";
// import { Toast } from "primereact/toast";
import { useToast } from "../../../../components/toast-provider";

const ImportCSV: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(setShowModalCSV(false));
      const file = event.target.files[0];
      Papa.parse(file, {
        complete: (results) => {
          try {
            const data = results.data as string[][];
            const nodes: any[] = [];
            const edges: any[] = [];
            const nodeMap: { [key: string]: any } = {};

            data.forEach((row, index) => {
              if (index === 0) return;

              const [name, interaction, phrases, targetName] = row;

              if (!name) return;

              let phrasesArray = JSON.parse(phrases);

              try {
                phrasesArray = JSON.parse(phrases);
              } catch (e) {
                toast.current?.show({
                  life: 5000,
                  severity: "error",
                  summary: "Erro",
                  detail: "Erro no formato da interação do usuário"
                });
              }

              const targetNode = nodeMap[targetName];
              if (!targetNode) {
                toast.current?.show({
                  life: 5000,
                  severity: "warn",
                  summary: "Erro",
                  detail:
                    "Um ou mais nodes estão apontnaod para um node de destino inexistente"
                });
              }

              if (!nodeMap[name]) {
                nodeMap[name] = {
                  id: uuidv4(),
                  name,
                  interaction: interaction || "",
                  phrases: phrasesArray,
                  targets: []
                };

                const nodeType = nodeMap[name].interaction
                  ? "defaultNode"
                  : "startNode";

                let interactionsArray;
                try {
                  interactionsArray = JSON.parse(nodeMap[name].interaction);
                } catch {
                  interactionsArray = [nodeMap[name].interaction];
                }

                const botInteractions = interactionsArray.map(
                  (interactionText: string) => {
                    const formattedText = interactionText.replace(
                      /\/r\/n/g,
                      "\n"
                    );

                    const interactionParts = formattedText
                      .split("\n")
                      .filter((part) => part.trim() !== "");

                    return {
                      id: uuidv4(),
                      text: JSON.stringify(
                        interactionParts.map((part) => ({
                          type: "paragraph",
                          children: [{ text: part }]
                        }))
                      ),
                      interaction: interactionText,
                      entityTypeId: -1,
                      entityName: "",
                      sourceEntityNodeId: ""
                    };
                  }
                );

                nodes.push({
                  id: nodeMap[name].id,
                  type: nodeType,
                  position: { x: 0, y: nodes.length * 100 },
                  data: {
                    label: nodeMap[name].name,
                    botInteractions,
                    userInteractions: [],
                    entityTypeId: -1,
                    entityTypeName: "",
                    sourceEntityNodeId: "",
                    level: 0,
                    first: false,
                    end: false
                  }
                });
              }

              if (targetName) {
                nodeMap[name].targets.push(targetName);
              }
            });

            data.forEach((row, index) => {
              if (index === 0) return;

              const [name, interaction, phrases, targetName] = row;
              if (!name) return;

              const node = nodeMap[name];
              if (!nodeMap[name]) return;
              const targetNode = nodeMap[targetName];

              let phrasesArray;
              try {
                phrasesArray = JSON.parse(phrases);
              } catch (e) {
                console.error(`JSON inválido em phrases: ${phrases}`);
                return;
              }

              if (targetNode) {
                edges.push({
                  id: uuidv4(),
                  source: node.id,
                  target: targetNode.id,
                  type: "default",
                  data: {
                    comparison: "Igual a",
                    phrases: phrasesArray,
                    entityTypeId: -1,
                    entityTypeName: ""
                  }
                });

                const userInteraction = {
                  id: uuidv4(),
                  source: node.id,
                  target: targetNode.id,
                  comparison: "Igual a",
                  phrases: phrasesArray,
                  entityTypeId: -1,
                  entityTypeName: ""
                };

                const existingNode = nodes.find((n) => n.id === node.id);
                if (existingNode) {
                  existingNode.data.userInteractions.push(userInteraction);
                }
              }
            });

            nodes.forEach((node, index) => {
              if (index === 0) {
                node.type = "startNode";
                node.data.first = true;
              }
              if (nodeMap[node.data.label].targets.length === 0) {
                node.type = "endNode";
                node.data.end = true;
              }
            });

            dispatch(zerarFlow());
            dispatch(zerarEdges());
            dispatch(initFlow(nodes));
            dispatch(initEdges(edges));
            toast.current?.show({
              life: 5000,
              severity: "success",
              summary: "success",
              detail: "Arquivo CSV importado com sucesso!"
            });
          } catch (error) {
            toast.current?.show({
              life: 5000,
              severity: "error",
              summary: "Erro",
              detail:
                "Erro ao importar o arquivo CSV. O arquivo está fora do padrão para importação"
            });
          }
        },
        header: false
      });
    }
  };

  const handleButtonClick = () => {
    document.getElementById("file-upload")?.click();
  };

  return (
    <div>
      <input
        type="file"
        accept=".csv"
        id="file-upload"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
      <Button
        label="Importar CSV"
        icon="pi pi-upload"
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default ImportCSV;
