import React from "react";
import { Button } from "primereact/button";
import Papa from "papaparse";
import { useAppSelector } from "../../../../redux/hooks";

const ExportCSV: React.FC = () => {
  const nodes = useAppSelector((state) => state.nodes);

  const nodeOrder = (nodes) => {
    const sortedNodes: any[] = [];
    const visited = new Set();
    const tempMarked = new Set();

    const visit = (node) => {
      if (visited.has(node.id)) {
        return;
      }
      if (tempMarked.has(node.id)) {
        throw new Error("Graph has cycles");
      }
      tempMarked.add(node.id);

      node.data.userInteractions.forEach((interaction: { target: string }) => {
        const targetNode = nodes.find((n) => n.id === interaction.target);
        if (targetNode) {
          visit(targetNode);
        }
      });

      tempMarked.delete(node.id);
      visited.add(node.id);
      sortedNodes.push(node);
    };

    nodes.forEach((node: { id: string }) => {
      if (!visited.has(node.id)) {
        visit(node);
      }
    });

    return sortedNodes.reverse();
  };

  const handleExport = () => {
    const sortedNodes = nodeOrder(nodes);
    const data = [];

    sortedNodes.forEach((node) => {
      const nameNode = node.data.label;
      const interactionBot = node.data.botInteractions.map((interaction) => {
        const parsedText = JSON.parse(interaction.text);
        return parsedText
          .map((paragraph) =>
            paragraph.children.map((child) => child.text).join("")
          )
          .join("/r/n");
      });

      const interactionBotString =
        interactionBot.length > 0
          ? `[${interactionBot
              .map((text) => `"${text.replace(/\n/g, "\r\n")}"`)
              .join(", ")}]`
          : '""';

      const userInteractions = node.data.userInteractions;

      if (userInteractions.length > 0) {
        userInteractions.forEach(
          (interaction: { phrases: string[]; target: string }) => {
            const interactionUser =
              interaction.phrases.length > 0
                ? `[${interaction.phrases
                    .map((phrase) => `"${phrase}"`)
                    .join(", ")}]`
                : "[]";
            const destinationNode =
              nodes.find((n) => n.id === interaction.target)?.data.label ||
              interaction.target;
            data.push({
              nameNode,
              interactionBot: interactionBotString,
              interactionUser,
              destinationNode
            });
          }
        );
      } else {
        data.push({
          nameNode,
          interactionBot: interactionBotString,
          interactionUser: '[""]',
          destinationNode: ""
        });
      }
    });

    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "nodes.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Button
      label="Exportar"
      icon="pi pi-download"
      onClick={handleExport}
      style={{ background: "transparent", color: "#212529", border: "none" }}
    />
  );
};

export default ExportCSV;
