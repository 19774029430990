import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const HeaderDiv = css`
  position: fixed;
  height: 79px;
  width: 100%;
  background-color: #fff;
  display: flex;
  left: 0;
  align-items: center;
  padding-left: 8rem;
  z-index: 2;
`;

export const ButtonBack = css`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  transition: all 0.2s;
  :hover {
    background-color: #024aae;
    cursor: pointer;
  }
`;

export const H1 = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #0a043c;
`;

export const IconButton = css`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  transition: all 0.2s;
  border: none;
  border-radius: 0.5rem;
  transition: all 0.2s;
  padding: 0 1rem;
  :hover {
    background-color: #024aae;
    cursor: pointer;
  }
`;
export const DivRight = css`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const ButtonBackground = css`
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 40px;
  border: none;
  border-radius: 0.5rem;
  transition: all 0.2s;
  padding-right: 1rem;
  :hover {
    background-color: #024aae;
    cursor: pointer;
  }
`;
export const DivIcon = css`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(4, 106, 243, 0.12);
`;

export const DivTextButton = css`
  margin-left: 0.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 62px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const IconButtonBackground = css`
  width: 18px;
`;

export const ButtonSalvar = css`
  margin: 0 1rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  justify-content: center;
  background: #046af3;
  box-shadow: 0px 4px 10px rgba(70, 64, 200, 0.24);
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  transition: all 0.2s;
  width: 10.625rem;
  height: 2.875rem;
  :hover {
    cursor: pointer;
    background-color: #024aae;
  }
`;

export const ContainerLeftModal = styled.div`
  position: absolute;
  background-color: #fff;
  width: 55%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  padding-left: 2rem;

  .titleDatatable {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
export const ContainerRightModal = styled.div`
  width: 45%;
  position: relative;
  height: 100%;
  background-color: #ff0025;
  color: white;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1rem;

  .imageTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    font-size: 12px;
  }

  .link {
    display: flex;
    align-items: center;
    width: 100%;
    // cursor: pointer;
  }
`;

export const LeftHeaderWhiter = styled.div`
  width: 55%;
  position: absolute;
  height: 100%;
  top: 0px;
  border-radius: 20px;
  background-color: white;
  border-top-right-radius: 3rem;
  border-top-left-radius: 1rem;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .p-dropdown {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .p-dropdown-clear-icon {
    margin-top: -7px;
  }
`;
