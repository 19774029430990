import React, { memo, useState } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import { useAppDispatch } from "../../redux/hooks";
import { changeLabel } from "../../redux/slices/nodes";
import { changedPhoneComponent } from "../../redux/slices/flowupdates";
import { useLocation, useParams } from "react-router-dom";

export default memo(({ data, isConnectable, selected, ...rest }: NodeProps) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const [valueLabel, setValueLabel] = useState(data.label);

  const handleOnblur = () => {
    changeLabel({
      nodeId: rest.id,
      label: valueLabel
    });

    dispatch(changedPhoneComponent(true));
  };

  return (
    <div
      style={{
        fontFamily: "Poppins, sans-serif",
        boxSizing: "border-box",
        padding: "10px",
        position: "relative",
        backgroundColor: "#ff7b7b",
        borderRadius: "12px",
        WebkitBoxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
        boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
        width: "196px",
        height: "59px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: selected ? "1px solid rgba(4, 106, 243, 1)" : "none"
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#555", top: -6, position: "absolute" }}
        isConnectable={isConnectable}
      />
      <div>
        <input
          style={{
            border: "none",
            margin: "0 auto",
            backgroundColor: "transparent"
          }}
          type="text"
          disabled={isTemplate}
          value={valueLabel}
          onBlur={handleOnblur}
          onChange={(e) => setValueLabel(e.target.value)}
        />
      </div>
    </div>
  );
});
