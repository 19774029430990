import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Baloon = css`
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 16px;
  padding: 1rem;
  padding-right: 3rem;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  position: relative;
  width: 100%;
`;
export const ImageSixPoints = css`
  margin-right: 0.5rem;
`;

export const ButtonPlus = css`
  position: absolute;
  width: 25px;
  height: 25px;
  right: 1.1rem;
  top: 28%;
  background-color: #d8e9f6;
  border-radius: 6px;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;

export const ButtonTrash = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  right: -0.6rem;
  top: 40%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;
export const Input = styled.input`
  width: 100%;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4c5862;
`;
