import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setActive,
  setBotActiveBotFlowId,
  setBotCreatedAt,
  setBotCreatedBy,
  setBotId,
  setBotLastServices,
  setBotServiceHours,
  setBotUpdateddAt,
  setBotVersions,
  setBotWorkspace,
  setCompanyId,
  setDefaultFallBackMessage,
  setName,
  setTarget,
  setWorkspaceId,
  setBotChatColor,
  setBotAvatarColor,
  setFirstBotInteractions,
  setBotWhatsappRedirect,
  setBotNameOnStart,
  setBotEmailOnStart,
  setBotPhoneOnStart,
  setBotWhatsId,
  setBotPhone,
  setBotURLMeta,
  setBotBusinessPhoneId,
  setBotBusinessAccountId,
  setBotTokenMeta,
  setBotURLWebhook,
  setBotTokenWebhook,
  setBotActiveMeta,
  setBotTransferId,
  updateBotAutomation,
  setToken
} from "../../redux/slices/bot";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BotsServices from "../../services/bots";
import {
  IBotActiveObject,
  IBotResume,
  iBotAutomationDto
} from "../../types/iBotResume";
import { BotId } from "./components/BotId";
import { CanaisIntegrados } from "./components/CanaisIntegrados";
import { UltimosAtendimentos } from "./components/UltimosAtendimentos";
import { UnderstoodMessages } from "./components/UnderstoodMessages";
import { Versionamento } from "./components/VersionamentoScript";
import {
  Container,
  ContainerAutomation,
  ContainerBotIdentification,
  ContainerBotResume,
  ContainerFirstRow,
  ThirdCollumn,
  MainContainer,
  LastContainer
} from "./styles";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import {
  setBotIdActive,
  setTokenActive
} from "../../redux/slices/flowInteractions";
import { Toast } from "primereact/toast";
import { BotAutomation } from "./components/botAutomation";
import { useToast } from "../toast-provider";
import { ProgressSpinner } from "primereact/progressspinner";

export const GestaoBot: React.FC = () => {
  const [botResumeErase, setBotResumeErase] = useState<IBotResume>(
    {} as IBotResume
  );
  const location = useLocation();

  const [botActiveObject, setBotActiveObject] = useState(
    {} as IBotActiveObject
  );
  const botResume = useAppSelector((state) => state.bot);
  const { company } = useAuth() as AuthContextType;
  const dispatch = useAppDispatch();
  const { GetBotResume } = BotsServices();
  const { botId } = useParams();
  const toast = useToast();
  const tokenActive = useAppSelector(
    (state) => state.flowInteractions.tokenActive
  );
  const [showContent, setShowContent] = useState(false);
  const initBotAutomationDTO: iBotAutomationDto = {
    botRepeatAutomationDto: {
      minutes: 0,
      times: 0
    },
    botTransferAutomationDto: {
      minutes: 0,
      callwebhook: false,
      workspaceId: 0,
      userId: 0,
      queue: ""
    },
    botCloseRoomAutomationDto: {
      minutes: 0,
      message: "",
      active: false
    }
  };

  useEffect(() => {
    const BotId = botId ? parseInt(botId) : 0;

    const getResumeBot = async () => {
      try {
        const botResumeRetorno = await GetBotResume(BotId);
        dispatch(setBotIdActive(BotId));
        dispatch(setBotId(botResumeRetorno?.id));
        // setActive, setCompanyId, setDefaultFallBackMessage, setName, setTarget, setWorkspaceId
        dispatch(setBotActiveBotFlowId(botResumeRetorno?.activeBotFlowId));
        dispatch(setBotCreatedAt(botResumeRetorno?.createdAt));
        dispatch(setBotUpdateddAt(botResumeRetorno?.updatedAt));
        dispatch(setBotServiceHours(botResumeRetorno?.serviceHours));
        dispatch(setBotCreatedBy(botResumeRetorno?.createdBy));
        dispatch(setBotChatColor(botResumeRetorno?.chatColor || "#d3d3d3"));
        dispatch(setBotAvatarColor(botResumeRetorno?.avatarColor || "#a4a4a4"));
        dispatch(setBotWhatsappRedirect(botResumeRetorno?.whatsappRedirect));
        dispatch(setBotNameOnStart(botResumeRetorno?.nameOnStart));
        dispatch(setBotEmailOnStart(botResumeRetorno?.emailOnStart));
        dispatch(setBotPhoneOnStart(botResumeRetorno?.phoneOnStar));
        dispatch(setName(botResumeRetorno?.name));
        dispatch(setActive(botResumeRetorno?.active));
        dispatch(setTarget(botResumeRetorno?.target));
        dispatch(setToken(botResumeRetorno?.token));
        dispatch(setBotWorkspace(botResumeRetorno?.workspace));
        dispatch(
          setDefaultFallBackMessage(botResumeRetorno?.defaulFallbackMessages)
        );
        dispatch(setCompanyId(company?.id));
        dispatch(setWorkspaceId(botResumeRetorno?.workspaceId));
        dispatch(setBotLastServices(botResumeRetorno?.lastServices));
        dispatch(setBotVersions(botResumeRetorno?.versions));
        dispatch(setWorkspaceId(botResumeRetorno?.workspaceId));
        dispatch(
          setFirstBotInteractions(botResumeRetorno?.liveChatInteractions)
        );
        setBotResumeErase(botResumeRetorno);
        botResumeRetorno.active
          ? setBotActiveObject({ name: "Ativo", code: true })
          : setBotActiveObject({ name: "Inativo", code: false });
        dispatch(setBotWhatsId(botResumeRetorno?.whatsapp?.id));
        dispatch(setBotPhone(botResumeRetorno?.whatsapp?.phone));
        dispatch(setBotURLMeta(botResumeRetorno?.whatsapp?.urlMeta));
        dispatch(setBotTokenMeta(botResumeRetorno?.whatsapp?.tokenMeta));
        dispatch(
          setBotBusinessPhoneId(botResumeRetorno?.whatsapp?.businessPhoneId)
        );
        dispatch(
          setBotBusinessAccountId(botResumeRetorno?.whatsapp?.businessAccountId)
        );
        dispatch(setBotURLWebhook(botResumeRetorno?.whatsapp?.urlWebhook));
        dispatch(setBotTokenWebhook(botResumeRetorno?.whatsapp?.tokenWebhook));
        dispatch(setBotActiveMeta(botResumeRetorno?.whatsapp?.on));
        dispatch(setBotTransferId(botResumeRetorno?.botTransferId));
        dispatch(setTokenActive(botResumeRetorno?.token));
        botResumeRetorno?.automationDto
          ? dispatch(updateBotAutomation(botResumeRetorno?.automationDto))
          : dispatch(updateBotAutomation(initBotAutomationDTO));
        setShowContent(true);
      } catch (error: any) {
        // console.error(error);
        toast?.current?.show({
          severity: "error",
          detail: error.message
        });
      }
    };
    if (!isNaN(BotId)) {
      getResumeBot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId, location.pathname, botResume.versions.length]);
  const toastGestaoBot = useAppSelector((state) => state.bot.toast);

  return (
    <div css={Container}>
      <Toast ref={toastGestaoBot} />

      <MainContainer>
        {!showContent ? (
          <ProgressSpinner />
        ) : (
          <>
            <ContainerFirstRow>
              <ContainerBotIdentification>
                <BotId
                  id={botResume.id}
                  name={botResume.name}
                  active={botActiveObject}
                  workspace={botResume.workspaceId}
                  services={botResume?.services}
                  createdBy={botResume.createdBy}
                />
              </ContainerBotIdentification>
              <ContainerAutomation>
                <BotAutomation />
              </ContainerAutomation>
              <ContainerBotResume>
                <ThirdCollumn>
                  <UnderstoodMessages />
                  <CanaisIntegrados
                    token={tokenActive}
                    toastRef={toastGestaoBot}
                  />
                </ThirdCollumn>
              </ContainerBotResume>
            </ContainerFirstRow>
            <LastContainer>
              <UltimosAtendimentos
                ultimosAtendimentos={botResume?.lastServices}
              />
              {botResume && botResume?.versions ? <Versionamento /> : null}
            </LastContainer>
          </>
        )}
      </MainContainer>
    </div>
  );
};
