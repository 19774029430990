import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { AutomationConfig, bot } from "../../../../assets";
import { Text } from "../../../Text";
import { Container, ContainerIcon, Divisor } from "./styles";
import { useEffect, useState } from "react";
import WorkspaceUserService from "../../../../services/workspaceUserService";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { InputSwitch, InputSwitchChangeParams } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { iBotAutomationDto } from "../../../../types/iBotResume";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateBotAutomation } from "../../../../redux/slices/bot";

const TimeRedoOptions = [
  { name: "Não refazer pergunta.", code: 0 },
  { name: "Refazer pergunta após 5min.", code: 5 },
  { name: "Refazer pergunta após 10min.", code: 10 },
  { name: "Refazer pergunta após 15min.", code: 15 },
  { name: "Refazer pergunta após 20min.", code: 20 },
  { name: "Refazer pergunta após 25min.", code: 25 },
  { name: "Refazer pergunta após 30min.", code: 30 },
  { name: "Refazer pergunta após 60min.", code: 60 }
];
const QuantidadeRedoOptions = [
  { name: "Até 1 vezes.", code: 1 },
  { name: "Até 2 vezes.", code: 2 },
  { name: "Até 3 vezes.", code: 3 },
  { name: "Até 4 vezes.", code: 4 },
  { name: "Até 5 vezes.", code: 5 }
];
const TimeTransferOptions = [
  { name: "Não transferir automaticamente.", code: 0 },
  { name: "Transferir após 15min.", code: 15 },
  { name: "Transferir após 30min.", code: 30 },
  { name: "Transferir após 60min.", code: 60 }
];
const typeTransferOnWorkspace = [
  { code: "USER", name: "Colaborador Específico" },
  { code: "2", name: "Sequencial" }
];
const FecharAutomationOptions = [
  { name: "Fechar sala após 15min.", code: 15 },
  { name: "Fechar sala após 30min.", code: 30 },
  { name: "Fechar sala após 60min.", code: 60 }
];

export const BotAutomation: React.FC = () => {
  const { getUsersByWorkspace } = WorkspaceUserService();
  const { user } = useAuth() as AuthContextType;
  const { companyId } = user;
  const dispatch = useAppDispatch();
  const workspaces = user?.workspaces?.workspaces;
  const botAutomation = useAppSelector((state) => state.bot?.botAutomationDto);

  // Refazer Automation
  // const [selectedRefazerTime, setSelectedRefazerTime] =
  //   useState<number>(10);
  // const [selectedQuantidade, setSelectedRefazerQuantidade] =
  //   useState<number>(botAutomation?.botRepeatAutomationDto?.times);

  // Transfer Automation
  // const [selectedTransferTime, setSelectedTransferTime] = useState<number>(0);
  // const [selectedTransferType, setSelectedTransferType] = useState<number>(0);
  const [selectedTransferTypeOnWorkspace, setSelectedTransferTypeOnWorkspace] =
    useState<number>(0);
  // const [selectedWorkspace, setSelectedWorkspace] = useState<number>(0);
  // const [selectedUser, setSelectedUser] = useState<IDropdownSelectedId>(
  //   initDropdownSelectedId
  // );
  const [usersFromWorksPaceSelected, setUsersFromWorksPaceSelected] = useState(
    []
  );
  // const [switchAutomationActive, setSwitchAutomationActive] =
  //   useState<boolean>(false);
  // const [selectedCloseRoomsTime, setSelectedCloseRoomsTime] = useState<number>(0);
  // const [msgCloseRooms, setMsgCloseRooms] = useState<string>("");
  // useEffect(() => {
  //   dispatch(updateBotAutomation({
  //     // botRepeatAutomationDto: {
  //       // minutes: selectedRefazerTime,
  //     //   times: selectedQuantidade
  //     // },
  //     botTransferAutomationDto: {
  //       minutes: selectedTransferTime,
  //       // eslint-disable-next-line no-unneeded-ternary
  //       callwebhook: selectedTransferTypeOnWorkspace === 2 ? true : false,
  //       workspaceId: selectedWorkspace,
  //       userId: selectedUser.id
  //     },
  //     botCloseRoomAutomationDto: {
  //       minutes: selectedCloseRoomsTime,
  //       message: msgCloseRooms
  //     }
  //   }))
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedQuantidade, selectedTransferTime, selectedTransferTypeOnWorkspace, selectedWorkspace,
  //   selectedUser, selectedCloseRoomsTime, msgCloseRooms])
  // Fechar Sala Automation

  // Functions
  useEffect(() => {
    const updateUsersList = async () => {
      if (Number(botAutomation?.botTransferAutomationDto?.workspaceId) > 0) {
        const retorno = await getUsersByWorkspace(
          companyId,
          botAutomation?.botTransferAutomationDto.workspaceId as number
        );
        setUsersFromWorksPaceSelected(retorno);
      }
    };
    updateUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    botAutomation?.botTransferAutomationDto?.workspaceId,
    botAutomation?.botTransferAutomationDto?.userId
  ]);
  const handleSelectTypeTransferOnWorkspace = async (
    e: DropdownChangeParams
  ) => {
    setSelectedTransferTypeOnWorkspace(e.value);
    if (e.value === "1") {
      const retorno = await getUsersByWorkspace(companyId, e.value);
      setUsersFromWorksPaceSelected(retorno);
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            userId: 0,
            queue: e.value
          }
        })
      );
    } else {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            userId: 0,
            queue: e.value
          }
        })
      );
    }
  };
  const setSwitch = (e: InputSwitchChangeParams) => {
    if (
      e.value === true &&
      botAutomation?.botTransferAutomationDto.minutes !== 0
    ) {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botCloseRoomAutomationDto: {
            minutes: 0,
            message: "",
            active: e.value
          },
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            minutes: 0
          }
        } as iBotAutomationDto)
      );
    } else {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botCloseRoomAutomationDto: {
            minutes: 0,
            message: "",
            active: e.value
          },
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            minutes: 0
          }
        } as iBotAutomationDto)
      );
    }
  };

  const SetTimeTransferOptions = (e: DropdownChangeParams) => {
    if (e.value === 0) {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botCloseRoomAutomationDto: {
            minutes: 0,
            message: "",
            active: false
          },
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            minutes: 0,
            callwebhook: false,
            workspaceId: 0,
            userId: 0,
            queue: ""
          }
        })
      );
    } else if (
      e.value !== 0 &&
      botAutomation?.botTransferAutomationDto.workspaceId === 0
    ) {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botCloseRoomAutomationDto: {
            minutes: 0,
            message: "",
            active: false
          },
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            minutes: e.value as number,
            callwebhook: false,
            workspaceId: null
          }
        })
      );
    } else {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botCloseRoomAutomationDto: {
            minutes: 0,
            message: "",
            active: false
          },
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            minutes: e.value as number,
            callwebhook: false
          }
        })
      );
    }
  };

  return (
    <Container>
      <div className="flex align-items-center justify-content-start gap-2 w-full">
        <ContainerIcon>
          <img src={AutomationConfig} alt="AutomationConfig" />
        </ContainerIcon>
        <Text s="1rem" weight={500} color="#0A043C">
          Automações
        </Text>
      </div>

      <Text
        style={{ textAlign: "left", width: "100%" }}
        s="0.875rem"
        weight={600}
        lh="3rem"
        color="#0A043C"
      >
        1. Refazer pergunta sem interação
      </Text>
      <Text
        style={{ textAlign: "left" }}
        s="0.75rem"
        weight={400}
        lh="1.3125rem"
        color="#0A043C"
      >
        Refaça um pergunta sem interação após x minutos, vamos usar a última
        interação do bot para refazer a pergunta.
      </Text>
      <div className="flex flex-column align-items-center w-full">
        <Dropdown
          style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
          value={botAutomation?.botRepeatAutomationDto.minutes}
          options={TimeRedoOptions}
          onChange={(e) =>
            dispatch(
              updateBotAutomation({
                ...(botAutomation as iBotAutomationDto),
                botRepeatAutomationDto: {
                  times:
                    (botAutomation?.botRepeatAutomationDto?.times as number) ||
                    0,
                  minutes: e?.value as number
                }
              })
            )
          }
          optionLabel="name"
          optionValue="code"
          placeholder="Refazer pergunta"
        />
        <Dropdown
          style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
          value={botAutomation?.botRepeatAutomationDto.times}
          options={QuantidadeRedoOptions}
          onChange={(e) =>
            dispatch(
              updateBotAutomation({
                ...(botAutomation as iBotAutomationDto),
                botRepeatAutomationDto: {
                  times: e.value as number,
                  minutes:
                    (botAutomation?.botRepeatAutomationDto
                      ?.minutes as number) || 0
                }
              })
            )
          }
          optionLabel="name"
          optionValue="code"
          placeholder="Refazer pergunta"
        />
        <Divisor />
        <Text
          style={{ textAlign: "left", width: "100%" }}
          s="0.875rem"
          weight={600}
          lh="3rem"
          color="#0A043C"
        >
          2. Transferir salas sem interação
        </Text>
        <Text
          style={{ textAlign: "left" }}
          s="0.75rem"
          weight={400}
          lh="1.3125rem"
          color="#0A043C"
        >
          Transferir automaticamente salas sem atividade ou interação para uma
          plataforma externa, Workspace ou usuário específico após X minutos.
        </Text>
      </div>
      <div className="flex flex-column align-items-center w-full">
        <Dropdown
          style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
          value={botAutomation?.botTransferAutomationDto?.minutes}
          options={TimeTransferOptions}
          onChange={(e) => SetTimeTransferOptions(e)}
          optionLabel="name"
          optionValue="code"
          placeholder="Transferir após"
        />

        {(botAutomation?.botTransferAutomationDto?.minutes || 0) > 0 ? (
          <>
            {Boolean(botAutomation?.botTransferAutomationDto?.callwebhook) ===
            false ? (
              <>
                <Dropdown
                  style={{ width: "100%", marginTop: "1rem" }}
                  value={botAutomation?.botTransferAutomationDto?.workspaceId}
                  options={workspaces}
                  onChange={(e) =>
                    dispatch(
                      updateBotAutomation({
                        ...(botAutomation as iBotAutomationDto),
                        botTransferAutomationDto: {
                          ...botAutomation?.botTransferAutomationDto,
                          workspaceId: e.value
                        }
                      })
                    )
                  }
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Selecione um workspace"
                  showClear={false}
                  filter={true}
                />
                {botAutomation?.botTransferAutomationDto?.workspaceId > 0 ? (
                  <>
                    <Dropdown
                      style={{
                        width: "100%",
                        marginTop: "1rem",
                        lineHeight: "1.5rem"
                      }}
                      value={botAutomation?.botTransferAutomationDto?.queue}
                      options={typeTransferOnWorkspace}
                      onChange={(e) => handleSelectTypeTransferOnWorkspace(e)}
                      optionLabel="name"
                      optionValue="code"
                      placeholder="Selecione o tipo de transferência"
                    />
                    {botAutomation?.botTransferAutomationDto?.queue ===
                    "USER" ? (
                      <Dropdown
                        style={{
                          width: "100%",
                          marginTop: "1rem",
                          lineHeight: "1.5rem"
                        }}
                        value={botAutomation?.botTransferAutomationDto?.userId}
                        options={usersFromWorksPaceSelected}
                        onChange={(e) =>
                          dispatch(
                            updateBotAutomation({
                              ...(botAutomation as iBotAutomationDto),
                              botTransferAutomationDto: {
                                ...botAutomation?.botTransferAutomationDto,
                                userId: e.value
                              }
                            })
                          )
                        }
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Selecione um colaboradoraa"
                        showClear={false}
                        filter={true}
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
        <Divisor />

        <div className="flex align-items-center mt-2 gap-2 justify-content-center">
          <InputSwitch
            checked={botAutomation?.botCloseRoomAutomationDto?.active}
            onChange={(e) => setSwitch(e)}
            inputId="switch1"
          />
          <label htmlFor="switch1">Fechar Sala</label>
        </div>
        {botAutomation?.botCloseRoomAutomationDto?.active ? (
          <>
            <Text
              style={{ textAlign: "left", marginTop: "0.75rem" }}
              s="0.75rem"
              weight={400}
              lh="1.3125rem"
              color="#0A043C"
            >
              Fechar automaticamente salas sem atividade ou interação após X
              minutos.
            </Text>
            <Dropdown
              style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
              value={botAutomation?.botCloseRoomAutomationDto?.minutes}
              options={FecharAutomationOptions}
              onChange={(e) =>
                dispatch(
                  updateBotAutomation({
                    ...(botAutomation as iBotAutomationDto),
                    botCloseRoomAutomationDto: {
                      ...botAutomation?.botCloseRoomAutomationDto,
                      minutes: e.value
                    }
                  })
                )
              }
              optionLabel="name"
              optionValue="code"
              placeholder="Fechar salas após"
            />
            <InputTextarea
              style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
              value={botAutomation?.botCloseRoomAutomationDto?.message}
              onChange={(e) =>
                dispatch(
                  updateBotAutomation({
                    ...(botAutomation as iBotAutomationDto),
                    botCloseRoomAutomationDto: {
                      ...botAutomation?.botCloseRoomAutomationDto,
                      message: e.target.value
                    }
                  })
                )
              }
              rows={5}
              autoResize={true}
              maxLength={500}
              placeholder="Favor inserir uma mensagem para o usuário quando do fechamento automático das salas."
            />
          </>
        ) : null}
      </div>
    </Container>
  );
};
